import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import "./SideNav.css"

const RecentPosts = ({ data }) => {
  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent ? { className: "active" } : {}
  }

  return (
    <div className="categories">
      <ul className="category-list">
        <li>
          <b>Recent Posts</b>
        </li>
        {data.nodes.map(post => {
          return (
            <li key={post.title}>
              <Link
                to={post.uri}
                itemProp="url"
                className="category-links"
                getProps={isPartiallyActive}
                activeClassName="active"
              >
                {post.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default RecentPosts
