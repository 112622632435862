import React from "react"
import { Link } from "gatsby"

import "./SideNav.css"

const Category = ({ data }) => {
  const isPartiallyActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent ? { className: "active" } : {}
  }

  return (
    <div className="categories">
      <ul className="category-list">
        <li>
          <b>Categories</b>
        </li>
        {data.nodes
          .filter(data => data.count > 0)
          .map(data => {
            return (
              <li key={data.name}>
                <Link
                  to={data.uri}
                  itemProp="url"
                  className="category-links"
                  activeClassName="active"
                  getProps={isPartiallyActive}
                >
                  {data.name}
                </Link>{" "}
                ({data.count})
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default Category
